export default class LightSource{

  constructor(location, color, brightness){
    this.location = location
    this.color = color
    this.brightness = brightness
  }

  tick(){
  }

}